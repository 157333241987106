#hero {
    background-image: url(../../../assets/img/banner1.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 23px;

    @media all and (max-width: 575px) {
        background-image: url(../../../assets/img/mobile-banner1.webp);
    }

    .head-contents {
        padding-top: 38px;
        padding-bottom: 87px;
        @media all and (max-width: 575px) {
            padding-top: 21px;
            padding-bottom: 56px;
        }
    }

    .outline-btn {
        color: #fff;
        leading-trim: both;
        text-edge: cap;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.28px;
        text-transform: uppercase;
        border-radius: 40px;
        border: 1px solid #fff;
        padding: 12px 15px;
        outline: none;
        background-color: transparent;
        margin-bottom: 16px;
    }

    h1 {
        color: #fff;
        font-size: 46px;
        font-weight: 700;
        line-height: 58px;
        letter-spacing: -0.92px;
        max-width: 771px;
        margin: 0;

        @media all and (max-width: 575px) {
            font-family: "Onest", sans-serif;
            font-size: 38px;
            line-height: 47px;
            letter-spacing: -1.52px;
        }
    }

    .company-img-group {
        display: flex;
        align-items: center;
        gap: 48px;
        margin-bottom: 22px;
        @media all and (max-width: 991px) {
            flex-wrap: wrap;
            gap: 20px;
            .img1 {
                width: 110px;
            }
            .img2 {
                width: 77px;
            }
            .img3 {
                width: 60px;
            }
            .img4 {
                width: 100px;
            }
            .img5 {
                width: 60px;
            }
        }
    }

    p {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.28px;
        text-transform: capitalize;
        margin: 0;
        @media all and (max-width: 575px) {
            font-size: 14px;
        }
    }
}
