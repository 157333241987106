.add-guest-dropdown {
    // position: relative;
    .guest-add-dropdown-contents {
        position: absolute;
        bottom: 50px;
        top: 110%;
        right: 0px;
        max-width: 405px !important;

        @media all and (max-width: 991px) {
            margin: 0 10px;
            top: 60%;
            left: 0px;
        }
        @media all and (max-width: 610px) {
            margin: 0 10px;
            top: 80%;
            left: 0px;
        }
    }

    h4 {
        color: #1e1e1e;
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.36px;
        margin: 0;
    }
    h5 {
        color: #1e1e1e;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.32px;
        margin: 0;
        cursor: pointer;
    }
    h6 {
        color: #64748b !important;
        font-size: 14px !important;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.28px;
        margin-bottom: 16px;
    }
    .companion-age {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .input-number-box {
        border-radius: 130px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        width: 137px;
        padding: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 25px;
        button {
            width: 34px;
            height: 34px;
            border-radius: 100%;
            aspect-ratio: 1 / 1;
            background-color: #f2f2f2;
            border: none;
            outline: none;
        }

        span {
            color: #475569;
            leading-trim: both;
            text-edge: cap;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.28px;
        }
    }
    .select-contents {
        padding: 20px !important;
    }

    .companion-age-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 16px;
        margin-bottom: 14px;
    }

    .alert-box {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #ef4444;
        leading-trim: both;
        text-edge: cap;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.28px;
        border-radius: 50px;
        background: rgba(239, 68, 68, 0.05);
        padding: 5px 12px 5px 5px;
        width: fit-content;
        margin-bottom: 14px;
    }

    .select-age-wrapper {
        // border-radius: 6px;
        // border: 1px solid #cbd5e1;
        // background: #f1f5f9;
        .form-select {
            background-color: transparent;
        }
    }

    .select-age-wrapper {
        // width: 145px;
        position: relative;
        margin-top: 8px;
    }

    select {
        border: none !important;
    }

    option {
        color: var(--black, #1e1e1e);
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.28px;
        &:hover {
            background-color: #fff !important;
        }
    }

    .select-age-btn {
        width: 232px;
        color: var(--slate-600, #475569);
        leading-trim: both;

        text-edge: cap;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.28px;
        border: none;
        outline: none;
        padding: 20px 15px;
        border-radius: 6px;
        border: 1px solid #cbd5e1;
        background: #f1f5f9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media all and (max-width: 575px) {
            padding: 12px;
        }
    }

    .select-content {
        position: absolute;
        top: 110%;
        border-radius: 8px;
        background: var(--Dark-D0, #fff);
        box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.1);
        padding: 12px 16px 16px;
        width: 145px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        height: 200px;
        overflow: scroll;
        .item {
            color: #1e1e1e;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.28px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .save-btn {
        width: 100%;
        color: var(--white, #fff);
        leading-trim: both;
        text-edge: cap;
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
        border-radius: 44px;
        background: #2563eb;
        line-height: 11px;
        padding: 19px;
        border: none;
        @media all and (max-width: 575px) {
            padding: 12px;
        }
    }
}
