.filter-box {
  border-radius: 11px;
  border: 1px solid #e2e8f0;
  background: #fff;
  padding: 20px 0;
  width: 100%;
  @media all and (max-width: 991px) {
    display: none;
  }
  h4 {
    color: #1e1e1e;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.36px;
    text-transform: capitalize;
    margin: 0;
  }

  .top-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 16.5px;
    border-bottom: 1px solid #e2e8f0;
    button {
      background-color: transparent;
      border: none;
    }
  }

  h5 {
    color: #64748b;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h6 {
    color: #1e293b;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;
  }

  .check-box-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 16px;
  }

  .vervoer,
  .accommodatietype,
  .rating {
    padding: 25px 20px 24px;
    border-bottom: 1px solid #e2e8f0;
  }

  .provider {
    padding: 25px 20px 24px;
  }
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 7px;
    vertical-align: middle;
    border: 2px solid #64748b;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    &:checked {
      background-color: #64748b;
    }
  }
  label {
    cursor: pointer;
  }

  .price {
    padding: 25px 20px 36px;
    border-bottom: 1px solid #e2e8f0;
  }

  .sm-box-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 24px;
    p {
      color: #64748b;
      font-size: 10px;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
    h2 {
      color: #000;
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
      margin: 0;
    }

    .input-number {
      outline: none;
      color: #000;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      &::placeholder {
        font-weight: 400;
        color: #000;
      }
    }

    .sm-box {
      border-radius: 7px;
      border: 1px solid #e2e8f0;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      padding: 10px 15px;
      gap: 3px;
    }
  }
  // accordian
  .accordion-button {
    padding: 0;
  }
  .accordion-header {
    // padding-bottom: 16px;
  }
  .accordion-button::after {
    display: none !important;
  }
  .accordion-button {
    .dropdown-icon {
      transform: rotate(180deg);
      transition: all 0.3s ease-in-out;
    }
  }
  .accordion-button:not(.collapsed) {
    .dropdown-icon {
      transform: rotate(0deg);
    }
  }

  .accordion-body {
    padding: 0;
  }
  .accordion-button:focus {
    box-shadow: none;
  }

  .accordion-button:not(.collapsed) {
    background-color: transparent !important;
    box-shadow: none;
  }

  .accordion-item {
    border: none;
  }
  .accordion {
    width: 100%;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
// price-range-slider
.price-range-slider {
  margin-top: 60px;
  .range-slider-wrapper {
    width: 100%;
    margin-bottom: 7px;
    @media all and (max-width: 991px) {
      padding: 0px 24px 0px;
    }
  }
  .input-range__slider {
    width: 16px !important;
    height: 16px !important;
    background-color: #fff !important;
    border: 2px solid #2563eb !important;
    top: 0px !important;
  }
  .input-range__track.input-range__track--active {
    background-color: #2563eb !important;
  }
  .input-range__label.input-range__label--value {
    background-color: #fff !important;
    border: 2px solid #e2e8f0 !important;
    padding: 5px 12px;
    position: relative !important;
    border-radius: 6px;
    &::after {
      content: "";
      background-color: #fff;
      position: absolute;
      border-bottom: 2px solid #e2e8f0 !important;
      border-right: 2px solid #e2e8f0 !important;
      width: 12px;
      height: 12px;
      transform: rotate(45deg);
      left: 0;
      right: 0;
      bottom: -7px;
      margin: 0 auto;
      border-radius: 2px;
    }
  }
  .input-range__label--value {
    top: -46px !important;
    left: -50% !important;
    span {
      color: #1e1e1e !important;
      leading-trim: both;

      text-edge: cap;
      font-family: "Open Sans" !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 700 !important;
      line-height: 12px; /* 100% */
      letter-spacing: -0.24px;
    }
  }
  .input-range__label-container {
    left: 0 !important;
    color: #64748b !important;
    font-family: "Open Sans";
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
    margin: 0;
  }
}

// mini_max_price_box
.mini_max_price_box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 40px;
  gap: 10px;
  .price_item {
    border-radius: 7px;
    border: 1px solid #e2e8f0;
    background: #fff;
    padding: 10px 15px;
  }
  h6 {
    color: #64748b;
    font-family: "Open Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
  .input_number {
    color: #000;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    @media all and (max-width: 991px) {
      .max-price-desktop {
        display: none;
      }
      .min-price-desktop {
        display: none;
      }
    }
    @media all and (min-width: 991px) {
      .max-price-mobile {
        display: none;
      }
      .min-price-mobile {
        display: none;
      }
    }
    align-items: center;
    margin-top: 3px;
  }
  input {
    color: #000;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    outline: none;
    width: 100%;
  }
}
