@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Plus+Jakarta+Sans:ital@0;1&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Onest:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Plus+Jakarta+Sans:ital@0;1&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  font-family: "Open Sans", sans-serif;
  scroll-behavior: smooth;
  font-style: normal;
  background-color: white;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.container {
  max-width: 1328px !important;
  margin: 0 auto;
  padding: 0 24px !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
button,
a,
div {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
}

@media all and (max-width: 575px) {
  .container {
    padding: 0 20px !important;
  }
}
::-webkit-scrollbar {
  display: none;
}
.outline-btn {
  color: #1e1e1e;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  text-transform: capitalize;
  padding: 10px;
  border-radius: 30px;
  border: 1px solid #1e1e1e;
  background-color: transparent;
  line-height: 10px !important;
}
.section-title {
  color: #1e1e1e;
  font-size: 40px;
  font-weight: 600;
  line-height: 57px;
  letter-spacing: -0.8px;
  text-transform: capitalize;
  margin: 0;
  @media all and (max-width: 575px) {
    font-size: 30px;
    line-height: 37px;
    letter-spacing: -0.6px;
  }
}

.rdrDefinedRangesWrapper {
  display: none;
}

div.elementor-shortcode > div.vjv-compare-tool > div.vjv-compare-tool > div#seach-search-bar {
  bottom: 0;
  margin: 50px 0 0 0 ;
}
