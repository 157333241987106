#header {
    .header-contents {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .logo {
        img {
            max-width: 215px;
        }
        @media all and (max-width: 991px) {
            flex-grow: 1;
            img {
                max-width: 185px;
            }
        }
    }

    nav {
        @media all and (max-width: 991px) {
            display: none;
        }
        ul {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
            gap: 6px;
            border-radius: 31px;
            border: 1px solid rgba(255, 255, 255, 0.14);
            background: rgba(255, 255, 255, 0.16);
            padding: 13px 1px;
            @media all and (max-width: 1200px) {
                padding: 11px 1px;
            }
            a {
                padding: 12px 13px;
                border-radius: 30px;
                @media all and (max-width: 1200px) {
                    padding: 10px 15px;
                }
            }
        }
    }

    .active-menu {
        background-color: #fff;
        color: #2563eb;
        font-weight: 700;
    }

    .right-side {
        @media all and (max-width: 1200px) {
            display: none;
        }
    }

    .menu-btn {
        display: none;
        @media all and (max-width: 991px) {
            display: block;
            width: 40px;
            height: 40px;
            border-radius: 5px;
            border-radius: 6px;
            border: 1px solid rgba(255, 255, 255, 0.14);
            background: rgba(255, 255, 255, 0.16);
        }
    }
}
#header:has(.search-style) {
    ul {
        background-color: #fff;
    }
    .active-menu {
        background-color: #3b82f6;
        color: #fff;
        font-weight: 700;
    }
    nav {
        ul {
            a {
                color: #1e1e1e;
            }
        }
    }

    .menu-btn {
        background-color: white;
    }
}

nav {
    ul {
        list-style: none;
        a {
            text-decoration: none;
            color: #fff;
            leading-trim: both;
            text-edge: cap;
            font-family: "Open Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 11px !important;
            &:hover {
                color: #ffffffb6;
                // font-weight: 700;
            }
        }
    }
}

.menu-offcanvas {
    width: 250px !important;
    // background-color: black !important;
    ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        a {
            color: black;
            font-weight: 600;
            &:hover {
                color: #2563eb;
            }
        }
    }
}
