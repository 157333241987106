#immerse-area {
    padding-top: 35px;
    padding-bottom: 70px;
    @media all and (max-width: 575px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    h1 {
        max-width: 637px;
    }

    .btn-groups {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        max-width: 482px;
        margin-top: 20px;
        @media all and (max-width: 575px) {
            margin-top: 15px;
        }
    }

    .immerse-card-contents {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        margin-top: 30px;
        @media all and (max-width: 668px) {
            grid-template-columns: repeat(2, 1fr);
            margin-top: 40px;
        }
        img {
            width: 100%;
            border-radius: 10px;
        }
    }

    h3 {
        color: #000;
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.36px;
        margin: 0;
        margin-top: 17px;

        @media all and (max-width: 668px) {
            margin-top: 14px;
            font-size: 16px;
            letter-spacing: -0.32px;
        }
    }
    p {
        color: #64748b;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.32px;
        margin: 0;
        margin-top: 4px;
        margin-bottom: 13px;

        @media all and (max-width: 668px) {
            margin-bottom: 12px;
            font-size: 14px;
            letter-spacing: -0.28px;
        }
    }
    a {
        color: #2563eb;
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
        width: fit-content;
        display: flex;
        gap: 7px;
        align-items: center;
        text-decoration: none;
        border-bottom: 2px solid #2563eb;
        padding-bottom: 7px;
        @media all and (max-width: 668px) {
            gap: 4px;
            font-size: 14px;
            letter-spacing: -0.28px;
        }
    }
}
