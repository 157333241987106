#search-hero {
    background-image: url(../../../assets/img/banner2.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 10;
    margin-bottom: 109px;
    @media all and (max-width: 991px) {
        margin-bottom: 38px;

        background-image: url(../../../assets/img/mobile-banner2.png);
    }
    .head-contents {
        padding-top: 38px;
        padding-bottom: 53px;
        @media all and (max-width: 575px) {
            padding-top: 20px;
            padding-bottom: 30px;
        }
    }
    .outline-btn {
        color: #fff !important;
        border: 1px solid #fff !important;
        display: block;
        font-size: 16px !important;
        font-weight: 500 !important;
        margin: 0 auto;
        @media all and (max-width: 575px) {
            font-size: 14px !important;
            margin: 0;
        }
    }
    h1 {
        color: #fff;
        text-align: center;
        font-size: 46px;
        font-weight: 700;
        line-height: 58px;
        letter-spacing: -0.92px;
        max-width: 766px;
        margin: 0 auto;
        margin-top: 16px;
        padding-bottom: 0px;
        @media all and (max-width: 575px) {
            font-family: Onest;
            font-size: 38px;
            line-height: 47px;
            letter-spacing: -0.76px;
            text-align: left;
            padding-bottom: 0px;
        }
    }
    .search-box {
        padding: 0 20px;
    }
}
