#enter-date {
    .enter-date-contents-wrapper {
        position: absolute;
        top: 110%;
        border-radius: 4px 4px 10px 10px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background: #fff;
        right: 25%;
        box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.1);
        padding: 20px 0 20px;
        width: fit-content;
        width: 640px;
        @media all and (max-width: 991px) {
            top: 40%;
            right: 10px;
            // padding-bottom: 10px;
        }
        @media all and (max-width: 880px) {
            top: 70%;
            left: 10px;
        }
        @media all and (max-width: 680px) {
            width: 95%;
        }
        @media all and (max-width: 608px) {
            top: 60%;
            left: 20px;
        }
        @media all and (max-width: 425px) {
            top: 60%;
            left: 5px;
        }
        .select-btn-wrapper {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
            gap: 16px;
            padding: 0 20px;
            // height: 50px;
            margin-top: 20px;
            @media all and (max-width: 680px) {
                gap: 32px;
            }
        }
        h5 {
            color: #1e1e1e;
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.28px;
            margin-bottom: 8px;
        }
        h3 {
            color: #1e1e1e;
            font-family: "Open Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.32px;
        }
        .select-btn {
            border-radius: 6px;
            border: 1px solid #cbd5e1;
            background: #f1f5f9;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            padding: 0 10px 0 15px;
            color: #1e1e1e;
            leading-trim: both;
            width: 100%;
            text-edge: cap;
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.28px;
            position: relative;
            .select-content {
                position: absolute;
                top: 110%;
                border-radius: 8px;
                background: var(--Dark-D0, #fff);
                box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.1);
                padding: 12px 16px 16px;
                width: 148px;
                display: flex;
                flex-direction: column;
                gap: 12px;
                height: 200px;
                overflow: scroll;
                z-index: 10;
                hr {
                    color: #2426272f;
                    margin: 0;
                }
                .item {
                    color: #1e1e1e;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: -0.28px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }
    }
    .maand-wrapper {
        h5 {
            padding: 0 20px 4px;
        }
        .select-btn-wrapper {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
            gap: 12px;
            padding: 0 20px;
            margin-top: 20px;
            @media all and (max-width: 680px) {
                gap: 12px;
            }
        }
    }
    .maad {
        @media all and (max-width: 991px) {
            height: 400px;
            overflow: scroll;
        }
        .select-btn {
            background-color: transparent;
            padding: 18px 16px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            justify-content: flex-start;
            gap: 10px;
        }
    }

    .tabs-contents {
        display: flex;
        align-items: center;
        gap: 40px;
        border-bottom: 1px solid #f1f5f9;
        button {
            color: #64748b;
            font-family: "Open Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            border: none;
            outline: none;
            background-color: transparent;
            padding-bottom: 13px;
            padding: 0 20px 13px 20px;
        }
        .active-tab {
            border-bottom: 1px solid #2563e9;
            color: #000;
        }
    }

    .bottom-btn-group {
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
        gap: 16px;

        .link-btn {
            background-color: transparent;
            border: none;
            color: #2563eb;
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: -0.28px;
            border-bottom: 1px solid #2563eb;
        }
    }
    input[type="checkbox"] {
        box-sizing: border-box;
        width: 22px;
        height: 22px;
        margin: 6px;
        padding: 0;
        border: 2px solid var(--checkbox-border-color);
        appearance: none;
        background-color: transparent;
        outline: none;
        transition: outline 0.1s;
        background-image: url(../../../assets/img/checkbox.png);
    }
    input[type="checkbox"]:checked {
        background-size: cover;
        padding: 2px;
    }

    input[type="checkbox"]:not(:disabled):checked {
        border-color: var(--checkbox-checked-color);
        background-image: url(../../../assets/img/checked.png);
    }

    .opslaan-btn {
        color: #fff;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: -0.28px;
        padding: 16px 30px;
        border-radius: 40px;
        background: #2561e6;
        border: none;
    }

    .flexibel {
        width: 200px !important;
        height: fit-content !important;
    }

    .date-picker-wrapper {
        position: absolute;
        top: 110%;
        border-radius: 4px 4px 10px 10px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background: #fff;
        left: 0%;
        box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.1);
        padding: 20px;
        width: fit-content;
        z-index: 11;
        @media all and (max-width: 375px) {
            left: -35px;
        }
    }
    h5 {
        cursor: pointer;
    }

    .rdrDayNumber {
        color: #1e1e1e !important;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: normal;
        letter-spacing: -0.32px;
    }

    .rdrDateDisplayWrapper,
    .rdrPprevButton {
        display: none;
    }

    .rdrMonthAndYearWrapper {
        padding: 0;
        margin-bottom: 14px;
    }

    .rdrMonthAndYearPickers {
        justify-content: flex-start;
    }
    .rdrWeekDay {
        color: #64748b !important;
        text-align: center;
        font-family: "Open Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.32px;
    }

    .rdrMonthAndYearPickers {
    }
    .rdrMonthPicker,
    .rdrYearPicker {
        border-radius: 6px;
        border: 1px solid var(--slate-300, #cbd5e1);
        background: var(--slate-100, #f1f5f9);
        select {
            color: var(--black, #1e1e1e);
            leading-trim: both;

            text-edge: cap;
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.28px;
            &::-ms-expand {
                display: none !important;
            }
        }
    }

    .rdrMonthAndYearPickers select {
        background-image: url(../../../assets/img/select-arrow.svg);
    }

    // .rdrDay{
    //     width: 15px;
    // }

    .rdrCalendarWrapper {
        font-size: 16px !important;
        color: #1e1e1e;
    }
    .rdrMonth {
        width: 390px !important;
        // height: 390px;
        @media all and (max-width: 575px) {
            // width: 200px !important;
        }
    }

    .rdrDayNumber > span {
        &::after {
            background-color: transparent !important;
        }
    }

    .rdrNextButton {
        background-color: transparent;
        background: url(../../../assets/img/arrow-right.svg);
        i {
            display: none;
        }
    }
    .rdrMonth {
        padding: 0;
    }

    .rdrMonth {
        overflow: scroll;

        @media all and (max-width: 575px) {
            width: 100% !important;
        }
    }

    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
        color: #2563eb;
    }

    .rdrStartEdge,
    .rdrEndEdge {
        color: #2563eb !important;
        .rdrDayNumber span {
            color: #fff !important;
        }
    }

    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
        border: none !important;
    }
}
