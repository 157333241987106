#travel-package-item {
    .travel-package-content-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        @media all and (max-width: 575px) {
            grid-template-columns: 37% 1fr;
            gap: 10px;
        }
        @media all and (max-width: 450px) {
            grid-template-columns: 1fr;
            gap: 20px;
        }
    }
    .outline-btn {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #1e1e1e !important;
        font-size: 14px;
        font-weight: 400 !important;
        line-height: normal;
        letter-spacing: -0.28px;

        @media all and (max-width: 575px) {
            padding: 6px !important;
            font-size: 12px;
            gap: 2px;
            svg {
                width: 14px;
            }
        }
        .bold-text {
            color: #1e1e1e;
            font-size: 14px;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.28px;
            @media all and (max-width: 575px) {
                font-size: 12px;
            }
        }

        .gray-text {
            color: #64748b;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.28px;
            @media all and (max-width: 575px) {
                font-size: 12px;
            }
        }
    }
    .slide-img {
        border-radius: 10px;
        height: 100%;
        @media all and (max-width: 575px) {
            height: 100% !important;
        }
    }
    .default-img {
        background-color: rgba(0, 0, 0, 0.5) !important; /* 30% black opacity */
        pointer-events: none;
    }
    .carousel.slide,
    .carousel-inner,
    .carousel-item {
        height: 100% !important;
    }

    .carousel-control-next,
    .carousel-control-prev {
        width: 37px;
        height: 37px;
        background-color: #fff;
        border-radius: 100%;
        margin: auto;
        opacity: 1 !important;
        @media all and (max-width: 575px) {
            display: none;
        }
        @media all and (max-width: 450px) {
            display: flex;
        }
    }
    .carousel-control-next {
        margin-right: 10px;
    }
    .carousel-control-prev {
        margin-left: 10px;
    }

    .carousel-indicators {
        button {
            width: 14px;
            height: 10px;
            border-radius: 30px;
            opacity: 0.3;
            background: #fff;
            border: none;
            @media all and (max-width: 575px) {
                width: 9.46px;
                height: 6.81px;
            }
        }

        button.active {
            width: 68px;
            border-radius: 30px;
            opacity: 1;
            @media all and (max-width: 575px) {
                width: 46px;
            }
        }
    }

    .share-btn {
        color: #fff;
        leading-trim: both;
        text-edge: cap;
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 12px 17px;
        border-radius: 30px;
        background: #10b981;
        border: none;
        @media all and (max-width: 575px) {
            font-size: 12px;
        }
    }

    h2 {
        color: #1e1e1e;
        font-size: 25px !important;
        font-weight: 800 !important;
        line-height: normal;
        letter-spacing: -0.5px;
        margin-top: 12px !important;
        margin-bottom: 0 !important;
        @media all and (max-width: 575px) {
            font-size: 16px !important;
        }
    }
    h3 {
        color: #1e1e1e;
        leading-trim: both;
        text-edge: cap;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.28px;
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 26px;
        margin-top: 17px;
        @media all and (max-width: 575px) {
            font-size: 12px;
            margin-top: 2px;
        }
    }
    .btn-groups {
        display: flex;
        flex-wrap: wrap;
        gap: 9px;
        max-width: 269px;
        @media all and (max-width: 575px) {
            gap: 6px;
            max-width: 214px;
        }
        @media all and (max-width: 450px) {
            gap: 9px;
            max-width: 100%;
        }
    }

    .bottom-contnets {
        margin-top: 17px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 25px;
        @media all and (max-width: 575px) {
            gap: 15px;
        }
        .box-item {
            border-radius: 10px;
            border: 1px solid #cbd5e1;
            background: #fff;
            padding: 31px 10px 20px 15px;
            display: flex;
            align-items: center;
            height: 100%;
            @media all and (max-width: 575px) {
                padding: 24px 10px 20px 14px;
            }
        }
        .img-side {
            padding-right: 8px;
            border-right: 2px dashed #cbd5e1;
            position: relative;
            height: 100%;

            .img-content {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    max-width: 78px;
                }
            }
            .half-circle1 {
                position: absolute;
                top: -41px;
                right: -10px;
                width: 19px;
                height: 19px;
                background-color: #fff;
                border-radius: 100%;
                border-bottom: 1px solid #cbd5e1;
                border-right: 1px solid #cbd5e1;
                border-top: 1px solid transparent;
                border-left: 1px solid transparent;
                transform: rotate(45deg);
                @media all and (max-width: 575px) {
                    top: -34px;
                }
            }
            .half-circle2 {
                position: absolute;
                bottom: -29px;
                right: -10px;
                width: 19px;
                height: 19px;
                background-color: #fff;
                border-radius: 100%;
                border-bottom: 1px solid #cbd5e1;
                border-right: 1px solid #cbd5e1;
                border-top: 1px solid transparent;
                border-left: 1px solid transparent;
                transform: rotate(225deg);
                @media all and (max-width: 575px) {
                    bottom: -28pxpx;
                }
            }
        }
        .img-item {
            display: flex;
            margin: auto 0;
            justify-content: center;
            align-items: center;
        }
        .text-side {
            width: 100%;
            flex-basis: 1;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-left: 15px;
            @media all and (max-width: 700px) {
                display: none;
            }
        }

        .text-side-sm-device {
            display: none;
            @media all and (max-width: 700px) {
                display: block;
                padding-left: 19px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                @media all and (max-width: 375px) {
                    padding-left: 10px;
                }
            }
        }
        .text-item {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
        h6 {
            color: #64748b;
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
            margin: 0;
            white-space: nowrap;
            @media all and (max-width: 375px) {
                font-size: 10px;
            }
        }
        h5 {
            color: #000;
            font-size: 14px;
            font-weight: 700;
            line-height: normal;
            margin: 0;
            white-space: nowrap;
            @media all and (max-width: 375px) {
                font-size: 12px;
            }
            span {
                font-weight: 400;
            }
        }
        h3 {
            color: #fb923c;
            font-size: 18px;
            font-weight: 700;
            line-height: normal;
            white-space: nowrap;
            margin: 0;
            @media all and (max-width: 375px) {
                font-size: 14px;
            }
        }
        .view-details-btn {
            border: none;
            background-color: transparent;
            color: #fff;
            leading-trim: both;
            font-family: "Open Sans";
            white-space: nowrap;
            text-edge: cap;
            font-size: 12px;
            font-weight: 700;
            line-height: normal;
            border-radius: 30px;
            background: #2563eb;
            padding: 12px 17px;
            line-height: 20px;

            &.disabled {
                background: #cbd5e1;
                color: #64748b;
            }
        }
    }
}

@media (min-width: 700px) and (max-width: 900px) {
    .plane-img {
        display: none !important;
    }

    .departure-return {
        display: none !important;
    }
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .plane-img {
        display: none !important;
    }

    .departure-return {
        display: none !important;
    }
}

.break-word {
    width: 35vw;
    word-wrap: break-word;
    white-space: normal !important;
}

.rating-box {
    border: 2px solid #cbd5e1;
    border-radius: 30px;
    padding: 10px;
    display: flex;
    cursor: default;
}
