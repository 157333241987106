#have-you {
    margin-bottom: 70px;
    @media all and (max-width: 575px) {
        margin-bottom: 50px;
    }

    h1 {
        max-width: 650px;
    }
    p {
        color: #334155;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: -0.36px;
        text-transform: capitalize;
        margin: 0;
        margin-bottom: 15px;
        margin-top: 15px;
        @media all and (max-width: 575px) {
            font-size: 14px;
            margin-bottom: 10px;
            margin-top: 10px;
        }
    }

    h5 {
        color: #334155;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: -0.36px;
        text-transform: capitalize;
        margin-bottom: 10px;
    }
    .btn-group {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        @media all and (max-width: 668px) {
            gap: 10px;
        }
        @media all and (max-width: 400px) {
            gap: 15px 10px;
        }
        .outline-btn {
            padding: 15px !important;
            font-size: 18px;
            @media all and (max-width: 668px) {
                font-size: 14px !important;
                padding: 11px !important;
                letter-spacing: -0.28px;
            }
            @media all and (max-width: 400px) {
                font-size: 12px !important;
                padding: 8px !important;
            }
        }
    }
}
