button {
  background-color: transparent;
  border: none;
}

.final-item {
  display: flex;
  align-items: flex-end;

  span {
    margin: 0px 5px;
  }
}
.first-item {
  display: flex;
  align-items: flex-end;

  span {
    margin: 0px 5px;
  }
}