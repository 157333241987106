#footer {
    background-color: #1e1e1e;
    padding-top: 37px;
    padding-bottom: 19px;
    @media all and (max-width: 575px) {
        padding-top: 27px;
        padding-bottom: 21px;
    }
    .footer-contents {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 50px;
        margin-bottom: 50px;
        @media all and (max-width: 991px) {
            margin-bottom: 40px;
            flex-direction: column;
            gap: 40px;
        }
    }

    p {
        color: #f4f4f5;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.28px;
        text-transform: capitalize;
        max-width: 397px;
        margin: 0;
    }

    h5 {
        color: #f4f4f5;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.28px;
        text-transform: capitalize;
        margin: 0;
    }

    a {
        color: #d4d4d8;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.28px;
        text-transform: capitalize;
        text-decoration: none;
        &:hover {
            color: white;
        }
    }

    .left-side {
        img {
            max-width: 215px;
            margin-bottom: 11px;
            @media all and (max-width: 575px) {
                max-width: 198px;
            }
        }
    }

    .right-side {
        display: flex;
        align-items: flex-start;
        gap: 89px;
        @media all and (max-width: 1150px) {
            gap: 50px;
        }
        @media all and (max-width: 991px) {
            width: 100%;
            gap: 25px;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .item {
            display: flex;
            flex-direction: column;
            gap: 11px;
        }
    }

    .input-box {
        display: flex;
        justify-content: space-between;
        border-radius: 7px;
        background: #27272a;
        height: 50px;
        width: 291px;
        padding: 6px 5px 5px 15px;
        // width: 100%;
        @media all and (max-width: 575px) {
            display: grid;
            width: 100%;
            grid-template-columns: repeat(5, 1fr);
        }
        input {
            grid-column: span 4;
            border: none;
            outline: none;
            background-color: transparent;
            color: #d4d4d8;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.28px;
            width: 100%;
            &::placeholder {
                color: #d4d4d8;
            }
        }
        button {
            border-radius: 5px;
            background: #fff;
            padding: 10px;
            border: none;
            color: #27272a;
            font-size: 14px;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.28px;
        }
    }
    .social-media-icons {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 5px;
    }

    .copyright-text {
        color: #d4d4d8;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.24px;
        text-transform: capitalize;
        text-align: center;
        width: 100%;
        display: block;
        border-top: 1px solid #52525b;
        padding-top: 20px;
        margin: 0;
    }
}
