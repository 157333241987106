#explore-place {
    margin-bottom: 70px;
    @media all and (max-width: 575px) {
        margin-bottom: 50px;
    }
    h1 {
        max-width: 622px;
        @media all and (max-width: 575px) {
            font-family: Onest;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: 37px;
            letter-spacing: -0.6px;
        }
    }
    img {
        height: 100% !important;
        border-radius: 13px;
        @media all and (max-width: 575px) {
            height: 10px;
        }
    }

    .slider-container {
        margin-top: 34px;
        @media all and (max-width: 575px) {
            margin-top: 21px;
        }
        div {
            outline: none !important;
        }
    }

    .slick-slide > div {
        margin: 0 10px;
        border: none !important;
        outline: none !important;
        height: 436px !important;
        @media all and (max-width: 1400px) {
            height: 350px !important;
        }
        @media all and (max-width: 1024px) {
            height: 296px !important;
        }

        @media all and (max-width: 575px) {
            height: 236px !important;
            margin: 0 5.5px;
        }
        @media all and (max-width: 390px) {
            height: 180px !important;
            margin: 0 5.5px;
        }
    }

    .slick-dots,
    .slick-arrow {
        display: none !important;
    }
}
