#select-destination {
    .select-location-contents {
        position: absolute;
        border-radius: 4px 4px 10px 10px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background: #fff;
        box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.1);
        padding: 16px 20px 20px;
        top: 110%;
        left: 0;
        @media all and (max-width: 991px) {
            top: 30%;
        }
        @media all and (max-width: 608px) {
            top: 20%;
        }
    }
    h5 {
        cursor: pointer;
    }

    .bottom-btn-group {
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
        gap: 16px;

        .link-btn {
            background-color: transparent;
            border: none;
            color: #2563eb;
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: -0.28px;
            border-bottom: 1px solid #2563eb;
        }
        .opslaan-btn {
            color: #fff;
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: -0.28px;
            padding: 16px 30px;
            border-radius: 40px;
            background: #2561e6;
            border: none;
        }

    }

    .select-destination-contents {
        position: absolute;
        border-radius: 4px 4px 10px 10px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background: #fff;
        box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.1);
        padding: 16px 20px 20px;
        top: 110%;
        left: 0;
        @media all and (max-width: 991px) {
            top: 30%;
        }
        @media all and (max-width: 608px) {
            top: 20%;
        }
        a {
            color: #2563eb;
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: -0.28px;
            border-bottom: 1px solid #2563eb;
        }

        .destination-grid-contents {
            margin: 14px 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            max-width: 656px;

            @media all and (max-width: 575px) {
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
                img {
                    width: 20px;
                }
            }
            .single-item {
                color: #1e1e1e;
                font-size: 16px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: -0.32px;
                display: flex;
                align-items: center;
                gap: 6px;
                cursor: pointer;
                @media all and (max-width: 991px) {
                    font-size: 14px;
                }
                @media all and (max-width: 360px) {
                    font-size: 12px;
                }
            }
        }
    }
}
