.vacation-contents {
  // display: grid;
  // grid-template-columns: 305px 1fr;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  transition: all 0.3s ease;
  .vac-sidebar {
    width: 305px;
    transition: all ease 0.3s;
    .inner-div {
      transition: all ease 0.3s;
    }
  }
  .vac-content {
    width: 0;
    flex-grow: 1;
  }
  &.active {
    grid-template-columns: repeat(1, 1fr);
    .vac-sidebar {
      width: 0;
      .inner-div {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
  > div {
    transition: all 0.3s ease;
  }
  @media all and (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.package-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
span {
  color: #64748b;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
}

.top-contents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 23px;
  @media all and (max-width: 991px) {
    flex-direction: column;
    margin-bottom: 18px;
    margin-top: 0;
  }
}

h2 {
  color: #1e1e1e;
  font-size: 20px;
  font-weight: 600 !important;
  line-height: normal;
  letter-spacing: -0.4px;
  margin: 0;

  @media all and (max-width: 991px) {
    width: 100%;
  }
  @media all and (max-width: 575px) {
    font-size: 16px;
    letter-spacing: -0.32px;
    font-weight: 800 !important;
  }
}

.select-title {
  color: #475569;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  white-space: nowrap;
}
.form-select,
option {
  width: fit-content !important;
  color: #1e293b;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}

.form-select {
  background-color: transparent;
  border: none !important;
  cursor: pointer;
}

.select-wrapper {
  border-radius: 3px;
  border: 1px solid #e2e8f0;
  background: #f1f5f9;
  outline: none !important;
}
.select-groups {
  display: flex;
  align-items: center;
  gap: 20px;
  @media all and (max-width: 991px) {
    display: none;
  }
}

.form-select div {
  outline: none !important;
}
