#travel-package {
    margin-bottom: 70px;
    @media all and (max-width: 575px) {
        margin-bottom: 50px;
    }
    h1 {
        margin-top: 10px;
        max-width: 437px;
        margin-bottom: 20px;
    }
    p {
        color: #334155;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: -0.36px;
        text-transform: capitalize;
        margin: 0;
        margin-bottom: 40px;
    }

    .travel-package-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px 15px;
        @media all and (max-width: 1250px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .see-more-btn {
        color: #2563eb;
        leading-trim: both;
        text-edge: cap;
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        border-radius: 30px;
        border: 1px solid #2563eb;
        display: inline-flex;
        padding: 18px 30px;
        background-color: transparent;
        display: block;
        width: fit-content;
        margin: 0 auto;
        margin-top: 40px;
        line-height: 11px;
        @media all and (max-width: 575px) {
            font-size: 14px;
            padding: 15px 25px;
        }
    }
}
